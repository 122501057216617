<template>
	<Configurator />
</template>

<script>
	import Configurator from './components/Configurator.vue'

	export default {
		name: 'App',
		components: {
			Configurator
		}
	}
</script>

<style lang="scss">
	@import url('https://api.fontshare.com/v2/css?f[]=satoshi@500&display=swap');	

	* {
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		-webkit-text-size-adjust: 100%;
	}
	html {
		font-family: 'Satoshi', sans-serif;
		-webkit-text-size-adjust: 100%;
		-ms-text-size-adjust:     100%;
		font-size: 14px;
		font-weight: 500;
		line-height: 1.2;
	}
	body.screenshot-mode{
		overflow: hidden;
	}
	a,
	a:visited,
	a:hover,
	a:focus,
	a:active {
		color: inherit;
	}
	
	h1,h2,h3,h4,h5{
		font-weight: 500;
	}
	
	.main-column {
		position: relative;
		margin: 0 auto;
		height: auto;
		width: 1280px;
	}
	.column-of-1 { width: 100% }
	.column-of-2 { width: 50% }
	.column-of-3 { width: 33.33% }
	.double-column-of-3 { width: 66.66% }
	.column-of-4 { width: 25% }
	.column-of-5 { width: 20% }

	.columns-1 { width: 8.33% }
	.columns-2 { width: 16.66%; }
	.columns-3 { width: 25%; }
	.columns-4 { width: 33.33%; }
	.columns-5 { width: 41.66%; }
	.columns-6 { width: 50%; }
	.columns-7 { width: 58.33%; }
	.columns-8 { width: 66.66%; }
	.columns-9 { width: 75%; }
	.columns-10 { width: 83.33%; }
	.columns-11 { width: 91,66%; }
	.columns-12 { width: 100%; }

	.column-of-1,
	.column-of-2,
	.column-of-3,
	.double-column-of-3,
	.column-of-4,
	.column-of-5,
	.columns-1,
	.columns-2,
	.columns-3,
	.columns-4,
	.columns-5,
	.columns-6,
	.columns-7,
	.columns-8,
	.columns-9,
	.columns-10,
	.columns-11,
	.columns-12{
			position: relative;
	}
	.outer-margins{
		margin: 0 -10px;
	}
	.large-outer-margins{
		margin: 0 -20px;
	}
	.small-col-padding{
		padding-left: 10px;
		padding-right: 10px;
	}
	.col-padding {
		padding-left: 20px;
		padding-right: 20px;
	}
	.double-col-padding {
		padding-left: 40px;
		padding-right: 40px;
	}
	.small-row-padding{
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.row-padding {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.double-row-padding {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	.bottom-padding {
		padding-bottom: 20px;
	}
	.left { position: relative; float: left; }
	.right { position: relative; float: right; }
	.center {
		position: relative;
		margin: 0 auto;
	}
	.clear { clear: both; }
	.cover{
		background-size: cover;
		background-position: center;
		object-fit: cover;
		-o-object-fit: cover;
		object-position: center;
	}
	ul li{
		list-style: none;
	}
	.uppercase{
		text-transform: uppercase;
	}
	.bold{
		font-weight: 700;
	}
	.text-center{
		text-align: center;
	}
	.text-right{
		text-align: right;
	}
	.flex{
		display: flex;
	}
	.flex.space{
		justify-content: space-between;
	}
	.flex.center{
		justify-content: center;
	}
	.flex.v-center {
		align-items: center;
	}
	.flex.v-top {
		align-items: start;
	}
	.flex.h-center {
		justify-content: center;
	}
	.flex.h-end {
		justify-content: flex-end;
	}
	.flex.column {
		flex-direction: column;
	}
	.button{
		outline: none;
		border: 1px solid black;
		padding: 8px 24px;
		text-align: center;
		font-size: 16px;
		border-radius: 24px;
		white-space: nowrap;
		cursor: pointer;
		user-select: none;
		
		&.large{
			padding: 12px 36px;
			font-size: 21px;
		}
		
		
		&.filled{
			background: black;
			color: white;
		}
		
		&:hover{
			background: #D4D4D4;
			color: black;
		}
		
		&.disabled{
			pointer-events: none;
		}
	}
	input[type=text],
	input[type=mail],
	select{
		outline: none;
		border: 1px solid black;
		padding: 8px 24px;
		text-align: center;
		font-size: 16px;
		border-radius: 24px;
	}
	
	.range-wrapper{
		position: relative;
		width: 100%;
		margin-right: 10px;
		height: 21px;
		
		 &:after{
			content: '';
			position: absolute;
			top: 9px;
			left: 0;
			width: 100%;
			height: 1px;
			background: black;
		 }
		 
		 input{
			width: 100%;
		 }
	}
	
	input[type=range] {
		-webkit-appearance: none;
		appearance: none;
		background: transparent;
	}
	input[type=range]:focus {
		outline: none; 
	}
	
	input[type="range"]::-webkit-slider-runnable-track,
	input[type="range"]::-moz-range-track {
		-webkit-appearance: none;
	}
	input[type="range"]::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		background-color: black;
		height: 21px;
		width: 21px;
		border-radius: 50%;
		cursor: pointer;
	}

	input[type="range"]::-moz-range-thumb {
		border: none;
		background-color: black;
		height: 21px;
		width: 21px;
		border-radius: 50%;
		cursor: pointer;
	}
	
	/* RANDOM */
	
	
	#canvas-container{
		.label{
			background: white;
			color: black;
			border: 1px solid black;
			border-radius: 50%;
			width: 30px;
			height: 30px;
			text-align: center;
			line-height: 27px;
			
			&.selected{
				background: black; 
				color: white;
						
				&.main{
					background: #FFFF00;
					color: black;
				}
			}
		}
		
		.measure-divider{
			position: relative;
			
			&:before{
				content: '';
				position: absolute;
				left: -6px;
				bottom: 2px;
				width: 10px;
				height: 10px;
				border: 1px solid black; 
				border-top: none;
				border-left: none;
				transform: rotate(45deg);
			}
			&:after{
				content: '';
				position: absolute;
				top: 2px;
				left: -6px;
				width: 10px;
				height: 10px;
				border: 1px solid black;
				border-bottom: none;
				border-right: none;
				transform: rotate(45deg);
			}
		}
		.measure-arrow-top{
			position: relative;
			
			&:before{
				content: '';
				position: absolute;
				left: -6px;
				bottom: 2px;
				width: 10px;
				height: 10px;
				border: 1px solid black; 
				border-top: none;
				border-left: none;
				transform: rotate(45deg);
			}
		}
		.measure-arrow-bottom{
			position: relative;
			
			&:after{
				content: '';
				position: absolute;
				top: 2px;
				left: -6px;
				width: 10px;
				height: 10px;
				border: 1px solid black;
				border-bottom: none;
				border-right: none;
				transform: rotate(45deg);
			}
		}
		
		&.camera-default .label:not(.selected),
		&.camera-top .measure-divider,
		&.camera-top .measure-label-wall,
		&.camera-top .measure-arrow-top,
		&.camera-top .measure-arrow-bottom {
			display: none;
		}
		
		
		&.screenshot{
			.label-container{
				display: none;
			}
		}
	}

	
	/* TYPOGRAPHY */
	
	.small-font{
		font-size: 0.8rem;
	}
	.medium-font{
		font-size: 1.5rem;	 /* 21px */
	}
	.large-font{
		font-size: 2rem;	 /* 28px */
	}
	.very-large-font{
		font-size: 3.64rem;  /* 51px */
	}
	.huge-font{
		font-size: 6.42rem;  /* 90px */
	}

	
	/* TRANSITIONS */
	
	.fade-enter-active,
	.fade-leave-active {
	  transition: opacity 0.5s ease;
	}

	.fade-enter-from,
	.fade-leave-to {
	  opacity: 0;
	}
	
	
	/* VIEWPORTS */
	
	
	@media screen and (max-width: 1260px) {
		.main-column{
			width: 95% !important;
		}
	}

	@media screen and (max-width: 767px) {
		.showOnMobile{
			display: block;
		}
		.dontShowOnMobile {
			display: none;
		}
	}
	
	@media screen and (max-width: 599px) {
		html{
			font-size: 12px;
		}
	}
</style>
