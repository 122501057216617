<template>
	<div id="configurator"> 
		<Transition name="fade">
			<Presets 
				v-if="show_presets"
				@start-configuration="startConfiguration"
			/>
		</Transition>
		
		<div class="wrapper flex">
			<div class="info row-padding col-padding" ref="info">
				<div class="go-back flex v-center" @click="show_presets = true">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M3.4748 12L10.7623 19.2875C10.9956 19.5208 11.1102 19.7958 11.1061 20.1125C11.1019 20.4292 10.9831 20.7042 10.7498 20.9375C10.5165 21.1708 10.2415 21.2875 9.9248 21.2875C9.60814 21.2875 9.33314 21.1708 9.0998 20.9375L1.4748 13.325C1.28314 13.1333 1.14355 12.925 1.05605 12.7C0.968555 12.475 0.924805 12.2417 0.924805 12C0.924805 11.7583 0.968555 11.525 1.05605 11.3C1.14355 11.075 1.28314 10.8667 1.4748 10.675L9.0998 3.05001C9.33314 2.81667 9.61022 2.70209 9.93105 2.70626C10.2519 2.71042 10.529 2.82917 10.7623 3.06251C10.9956 3.29584 11.1123 3.57084 11.1123 3.88751C11.1123 4.20417 10.9956 4.47917 10.7623 4.71251L3.4748 12Z" fill="black"/>
					</svg>
					
					<div>{{ locale.back_to_preset }}</div>
				</div>
				
				<h1 class="very-large-font row-padding">MULTIDOT</h1>
				
				<div class="toggle-panel">
					<div class="panel-header flex space v-center" @click="toggles.ambient = !toggles.ambient">
						<div class="medium-font">{{ locale.room }}</div>
						
						<ToggleIcon v-model="toggles.ambient" />
					</div>
					
					<slide-up-down :active="toggles.ambient" :duration="300">
						<div class="panel-content">
							<div class="field">
								<label>{{ locale.room_height }}</label>
								
								<div class="range-container flex v-center">
									<div class="range-wrapper">
										<input type="range" min="240" :max="advanced_mode ? 1200 : 500" v-model="room_height_model" @input="changeRoomHeight"/>
									</div>
									
									<InputButton v-model="room_height_model" @change="changeRoomHeight" label="cm" :min="240" :max="advanced_mode ? 1200 : 500" />
								</div>
							</div>
							
							<div class="field">
								<label>{{ locale.furniture }}</label>
								
								<Switch 
									v-model="room_decoration"
									@change="changeFurniture"
									:options="[
										{ text: '01', value: 'Divani' },
										{ text: '02', value: 'Tavolo_grande' },
										{ text: '03', value: 'Tavolo_piccolo' }
									]"
								/>
							</div>
							
						</div>
					</slide-up-down>
				</div>
				
				<div class="toggle-panel">
					<div class="panel-header flex space v-center" @click="toggles.general = !toggles.general">
						<div class="medium-font">{{ locale.general_settings }}</div>
						
						<ToggleIcon v-model="toggles.general" />
					</div>
					
					<slide-up-down :active="toggles.general" :duration="300">
						<div class="panel-content">
							<!--
							<div class="field">
								<label>Rosone</label>
								
								<Switch 
									v-model="base_type"
									:options="[
										{ text: 'Circolare', value: 'circular' },
										{ text: 'Anglare', value: 'angular' }
									]"
								/>
							</div>
							-->
							
							<div class="field">
								<label>{{ locale.cable_number }}</label>
								
								<Switch 
									v-model="cables_number"
									@change="changeCablesNumber"
									:options="
										!advanced_mode ?
										[{ text: '4', value: 4 }, { text: '8', value: 8 }, { text: '12', value: 12 }] :
										[{ text: '4', value: 4 }, { text: '8', value: 8 }, { text: '12', value: 12 }, { text: '18', value: 18 }, { text: '24', value: 24 }]
									"
								/>
							</div>
							
							<div class="field">
								<label>{{ locale.light_color }}</label>
								
								<Switch 
									v-model="light_color"
									@change="changeLightsColor"
									:options="[
										{ text: '2700 k', value: 2700 },
										{ text: '3000 k', value: 3000 },
										{ text: '4000 k', value: 4000 }
									]"
								/>
							</div>
						</div>
					</slide-up-down>
				</div>
				
				<div class="panel">
					<div class="panel-header">
						<div class="medium-font">{{ locale.select_cables }}</div>
					</div>
					<div class="panel-content" :class="{
						'incongruent-type' : incongruent_cables.type,
						'incongruent-spheres' : incongruent_cables.spheres,
						'incongruent-length' : incongruent_cables.length,
					}">
						<div class="flex space">
							<div class="select-all" :title="locale.select_all">
								<svg @click="selectAll" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="black"/>
									<mask id="mask0_2376_909" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="8" y="8" width="24" height="24">
									<rect x="8" y="8" width="24" height="24" fill="#D9D9D9"/>
									</mask>
									<g mask="url(#mask0_2376_909)">
									<path d="M22.5 23.3077H23.5V20.5H26.3077V19.5H23.5V16.6923H22.5V19.5H19.6923V20.5H22.5V23.3077ZM14 27.4231C12.491 26.7897 11.2804 25.8029 10.3683 24.4625C9.45608 23.1221 9 21.6346 9 20C9 18.3654 9.45608 16.8779 10.3683 15.5375C11.2805 14.1971 12.491 13.2103 14 12.5769V13.7C12.7667 14.2833 11.7917 15.1417 11.075 16.275C10.3583 17.4083 10 18.65 10 20C10 21.35 10.3583 22.5917 11.075 23.725C11.7917 24.8583 12.7667 25.7167 14 26.3V27.4231ZM23.0011 28C21.8914 28 20.8516 27.7914 19.8817 27.3741C18.9119 26.9567 18.0644 26.3856 17.3394 25.6606C16.6144 24.9356 16.0433 24.0885 15.6259 23.1194C15.2086 22.1502 15 21.1108 15 20.0011C15 18.8914 15.2086 17.8516 15.6259 16.8817C16.0433 15.9119 16.6144 15.0644 17.3394 14.3394C18.0644 13.6144 18.9115 13.0433 19.8806 12.626C20.8498 12.2087 21.8892 12 22.9989 12C24.1086 12 25.1484 12.2087 26.1183 12.626C27.0881 13.0433 27.9356 13.6144 28.6606 14.3394C29.3856 15.0644 29.9567 15.9115 30.3741 16.8806C30.7914 17.8498 31 18.8892 31 19.9989C31 21.1086 30.7914 22.1484 30.3741 23.1183C29.9567 24.0881 29.3856 24.9356 28.6606 25.6606C27.9356 26.3856 27.0885 26.9567 26.1194 27.3741C25.1502 27.7914 24.1108 28 23.0011 28Z" fill="black"/>
									</g>
								</svg>
							</div>
							
							<Switch 
								v-if="advanced_mode"
								v-model="unbounded_mode"
								:options="[
									{ value:false, icon:'bounded_mode', title:'restricted_mode'},
									{ value:true, icon:'unbounded_mode', title:'unrestricted_mode'},
								]"
							/>
								
							<div class="deselect-all" :title="locale.deselect_all">
								<svg v-if="selected_cables.length > 1" @click="deselectAll" width="41" height="40" viewBox="0 0 41 40" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect x="1" y="0.5" width="39" height="39" rx="19.5" stroke="black"/>
									<mask id="mask0_2382_1124" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="8" y="8" width="25" height="24">
										<rect x="8.5" y="8" width="24" height="24" fill="#D9D9D9"/>
									</mask>
									<g mask="url(#mask0_2382_1124)">
										<path d="M16.9014 24.3328L16.1675 23.6059L19.7663 20L16.1675 16.4193L16.9014 15.6924L20.5003 19.2885L24.0739 15.6924L24.8079 16.4193L21.2091 20L24.8079 23.6059L24.0739 24.3328L20.5003 20.7367L16.9014 24.3328Z" fill="#1C1B1F"/>
									</g>
								</svg>
							</div>
						</div>
						
						<div 
							class="base-schema" 
							:class="{'select-mode' : selected_cables.length > 0}"
							:style="'--m: '+cables_number+';--tan: '+(Math.tan(Math.PI/cables_number))"
						>
							<div 
								v-for="n in cables_number" 
								class="cable"
								:class="{selected : selected_cables.indexOf(n-1) != -1, main : selected_cables.indexOf(n-1) == 0}"
								:style="'--i: '+n"
								@click="selectCable(n-1)"
							>
								<span class="label">{{ cables_letters[n-1] }}</span>
								<span class="add">+</span>
							</div>
							
						</div>
						
						<div v-if="selected_cables.length > 0">
							<div class="field cable-type">
								<label>{{ locale.cable }}</label>
								
								<Switch 
									v-model="current_cable.userData.type"
									@change="changeCableType"
									:options="[
										{ text: locale.detached, value: 'detached' },
										{ text: locale.attached, value: 'attached' },
									]"
								/>
							</div>
							
							<div class="field spheres-number">
								<label>{{ locale.spheres_number }}</label>
								
								<Switch
									class="small-padding"
									v-model="current_cable.userData.lights_number"
									@change="changeLightsNumber"
									:options="
										!advanced_mode ?
										[{ text: '8', value: 8 }, { text: '12', value: 12 }, { text: '16', value: 16 }] : 
										[{ text: '4', value: 4 }, { text: '8', value: 8 }, { text: '12', value: 12 }, { text: '16', value: 16 }, { text: '24', value: 24 }, { text: '32', value: 32 }]
									"
								/>
							</div>
							
							<div class="field spheres-number" v-if="advanced_mode">
								<label>{{ locale.lights_distance }}</label>
								
								<Switch
									class="small-padding"
									v-model="current_cable.userData.lights_distance"
									@change="changeLightsDistance"
									:options="[
										{ text: '11', value: 11 }, 
										{ text: '20', value: 20 }, 
										{ text: '30', value: 30 }
									]"
								/>
							</div>
							
							<div class="field">
								<label>{{ locale.cable_offset }}</label>
								
								<div class="range-container flex v-center">
									<div class="range-wrapper">
										<input type="range" min="10" :max="max_cable_canopy_length" v-model="current_cable_canopy_length" @input="changeCableCanopyLength"/>
									</div>
									
									<InputButton v-model="current_cable_canopy_length" @change="changeCableCanopyLength" label="cm" :min="10" :max="max_cable_canopy_length" />
								</div>
							</div>
							
							<div class="cables-table">
								<table>
									<tr>
										<th></th>
										<th>{{ locale.cable_offset_short }}</th>
										<th>{{ locale.canopy_hook }}</th>
										<th>{{ locale.height }}</th>
									</tr>
									<tr v-for="cable in selected_cables">
										<td>{{ cables_letters[cable] }}</td>
										<td>{{ cables_table_data[cable].canopy_to_sphere }} cm</td>
										<td>{{ cables_table_data[cable].radius }} cm</td>
										<td>{{ cables_table_data[cable].height }} cm</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		
			<div class="view"> 
				<div id="canvas-container" :class="['camera-'+current_camera, recap ? 'screenshot' : ''  ]"></div>
				
				<div class="camera-control" v-if="room_height >= 600">
				
					<div class="range-container flex v-center">
						<div class="range-wrapper">
							<input v-model="camera_height" type="range" :min="-(room_height-500)/2" :max="(room_height-500)/2" @input="changeCameraHeight"/>
						</div>
					</div>
				</div>
				
				<div class="actions flex space">
					<div class="button large" @click="toggleCamera">
						<div class="flex v-center" v-if="current_camera == 'default'">
							<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
								<mask id="mask0_2376_945" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
									<rect y="0.5" width="24" height="24" fill="#D9D9D9"/>
								</mask>
								<g mask="url(#mask0_2376_945)">
									<path d="M3 21.5V16.5H5V19.5H8V21.5H3ZM16 21.5V19.5H19V16.5H21V21.5H16ZM3 8.5V3.5H8V5.5H5V8.5H3ZM19 8.5V5.5H16V3.5H21V8.5H19Z" fill="#1C1B1F"/>
								</g>
							</svg>

							{{ locale.viewtop }}
						</div>
						<div class="flex v-center" v-else>
							<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
								<mask id="mask0_2376_939" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
									<rect y="0.5" width="24" height="24" fill="#D9D9D9"/>
								</mask>
								<g mask="url(#mask0_2376_939)">
									<path d="M11 19.925V13.075L5 9.6V16.45L11 19.925ZM13 19.925L19 16.45V9.6L13 13.075V19.925ZM12 11.35L17.925 7.925L12 4.5L6.075 7.925L12 11.35ZM4 18.2C3.68333 18.0167 3.4375 17.775 3.2625 17.475C3.0875 17.175 3 16.8417 3 16.475V8.525C3 8.15833 3.0875 7.825 3.2625 7.525C3.4375 7.225 3.68333 6.98333 4 6.8L11 2.775C11.3167 2.59167 11.65 2.5 12 2.5C12.35 2.5 12.6833 2.59167 13 2.775L20 6.8C20.3167 6.98333 20.5625 7.225 20.7375 7.525C20.9125 7.825 21 8.15833 21 8.525V16.475C21 16.8417 20.9125 17.175 20.7375 17.475C20.5625 17.775 20.3167 18.0167 20 18.2L13 22.225C12.6833 22.4083 12.35 22.5 12 22.5C11.65 22.5 11.3167 22.4083 11 22.225L4 18.2Z" fill="#1C1B1F"/>
								</g>
							</svg>

							{{ locale.view3d }}
						</div>
					</div>
					
					<div class="button filled large" :class="{disabled : recap}" @click="prepareRecap">{{ locale.proceed }}</div>
				</div>
				
				<div class="reset-button button" @click="resetConfiguration">{{ locale.reset }}</div>
			</div>
		</div>
	
		<Recap :class="{ show : recap}"/>
		
		<div class="mobile-overlay flex v-center h-center">
			<div class="large-font text-center col-padding">Per utilizzare il configuratore è necessario visualizzare la pagina da desktop</div>
			<div class="double-row-padding col-padding"><a class="button" target="_blank" href="https://martinelliluce.it/it/">{{ locale.discover_multidot }}</a></div>
		</div>
	</div>
</template>

<script>
	import { mapState, mapMutations } from 'vuex'
	import Recap from '@/components/Recap.vue'
	import Presets from '@/components/Presets.vue'
	import ToggleIcon from '@/components/ToggleIcon.vue'
	import Switch from '@/components/Switch.vue'
	import InputButton from '@/components/InputButton.vue'
	import SlideUpDown from 'vue-slide-up-down'
	
	import * as THREE from 'three';
	import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';
	import { FBXLoader } from 'three/addons/loaders/FBXLoader.js'
	import { GLTFExporter } from 'three/addons/exporters/GLTFExporter.js'
	import { OBJExporter } from 'three/addons/exporters/OBJExporter.js';
	import { MultidotExporter } from '@/exporters/MultidotExporter.js';
	import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
	import { TransformControls } from 'three/addons/controls/TransformControls.js';
	import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
	import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
	import { UnrealBloomPass } from 'three/addons/postprocessing/UnrealBloomPass.js';
	import { OutputPass } from 'three/addons/postprocessing/OutputPass.js';
	import { CSS2DRenderer, CSS2DObject } from 'three/addons/renderers/CSS2DRenderer.js';
	import { getCatenaryCurve } from 'catenary-curve'
	import JSZip from 'jszip';
	import { saveAs } from 'file-saver';
	
	var scene, renderer, composer, loader, camera_default, camera_top, camera, controls, silhouette;
	var controls_top, effect, transform_control, label_renderer, furniture, silhouette, measure_group;
	var defaultCameraStartingPosition = new THREE.Vector3(0,0,325);
	var screenshotCameraStartingPosition = new THREE.Vector3(0,0,400);
	var advancedScreenshotCameraStartingPosition = new THREE.Vector3(0,0,1000);
	var topCameraStartingPosition = new THREE.Vector3(0,200,0);
	var mouse = new THREE.Vector2( 1, 1 );
	var box = null; var floor_plane = null; var ceiling_plane = null;
	var anchors = null;
	var cables = null;
	var end_blocks = null;
	var base_group = null;
	var bounding_box = null;
	var dragging = false;
	var frustum_size = 500;
	const bloom_scene = 1;
	
	var particleLight;
	var sphere_radius = 1.5;
	
	export default {
		name: 'Configurator',
		components: { SlideUpDown, ToggleIcon, Switch, InputButton, Presets, Recap },
		data: function () { return {
			window_width : window.innerWidth,
			show_presets : true,
			toggles : {
				ambient : false,
				general : false,
				cables : true,
			},
			advanced_mode : false,
			unbounded_mode : false,
			room_decoration : 'Divani',
			room_height : 300,
			room_height_model : 300,
			camera_height : 0,
			base_type : 'circular',
			anchor_points : [],
			radial_planes : [],
			cables_number : 4,
			cables_letters : 'ABCDEFGHIJKLMNOPQRSTUVWX',
			default_cable_length : 370,
			default_cable_offset : 200,
			max_offset : 240,
			max_cable_length: 370,
			max_cable_canopy_length : 200,
			current_offset : 120,
			current_cable_canopy_length : 100,
			current_length : 250,
			current_cable : null,
			selected_cables : [],
			cables_table_data : [],
			lights_distance : 11,
			lights_number : 8,
			light_color : 2700,
			current_camera : 'default',
			bounds : {
				height : 0,
				width : 0,
				depth : 0,
			},
			reset_data : null,
			base_data : {
				radius : 17.5,
				height : 6.35
			},
			wheel_delta : 100
		}},
		computed: {
			...mapState([
				'locale', 'recap', 'stop_animation', 
			]),
			anchor_point_base_height(){
				return this.room_height/2 - this.base_data.height;
			},
			incongruent_cables(){
				var incongruences = {
					type : false,
					spheres : false,
					length : false,
					distance : false,
				}
				
				var type = null, spheres = null, length = null, distance = null;
				
				if(this.selected_cables.length > 1) {
					this.selected_cables.forEach( i => {
						let cable = cables.getObjectByName('cable_'+i);
						
						if(type == null || type == cable.userData.type){
							type = cable.userData.type;
						}else{
							incongruences.type = true;
						}
						
						if(spheres == null || spheres == cable.userData.lights_number){
							spheres = cable.userData.lights_number
						}else{
							incongruences.spheres = true;
						}
						
						if(distance == null || distance == cable.userData.lights_distance){
							distance = cable.userData.lights_distance
						}else{
							incongruences.distance = true;
						}
						
						if(length == null || length == cable.userData.offset){
							length = cable.userData.offset
						}else{
							incongruences.length = true;
						}
					});
				}
				
				return incongruences;
			}
		},
		mounted(){
			this.window_width = this.getWindowWidth();
			this.setupScene();
		},
		methods: {
			...mapMutations([
				'setRecap', 'setImage1', 'setModelFile', 'setConfigurationData'
			]),
			setupScene(){
				//Scene
				scene = new THREE.Scene();
				scene.background = new THREE.Color( 0x878787 );
				
				//Camera
				let aspect = this.window_width / window.innerHeight;
				camera_default = new THREE.PerspectiveCamera( 70, aspect, 1, 2000 );
				camera_top = new THREE.OrthographicCamera( frustum_size * aspect / - 2, frustum_size * aspect / 2, frustum_size / 2, frustum_size / - 2, 1, 3000 );
				camera_default.position.copy(defaultCameraStartingPosition);
				camera_top.position.copy(topCameraStartingPosition);
				camera_default.layers.enable(1);
				camera_top.layers.enable(2);
				camera = camera_default;
				
				//Lights
				scene.add( new THREE.AmbientLight( 'white', 1 ) );
							
				//Renderer
				renderer = new THREE.WebGLRenderer( { antialias: true , preserveDrawingBuffer: true } );
				renderer.setPixelRatio( window.devicePixelRatio );
				renderer.setSize( this.window_width, window.innerHeight );
				renderer.toneMapping = THREE.ReinhardToneMapping;
				renderer.domElement.id = 'canvas';
				document.getElementById('canvas-container').appendChild( renderer.domElement );
				
				//Label renderer
				label_renderer = new CSS2DRenderer();
				label_renderer.setSize( this.window_width, window.innerHeight );
				label_renderer.domElement.classList.add('label-container'); 
				label_renderer.domElement.style.position = 'absolute';
				label_renderer.domElement.style.top = '0px';
				label_renderer.domElement.style.pointerEvents = 'none';
				label_renderer.domElement.style.pointerEvents = 'none';
				document.getElementById( 'canvas-container' ).appendChild( label_renderer.domElement );
				
				//Effects
				const outputPass = new OutputPass();
				const render_pass = new RenderPass( scene, camera );
				const bloom_pass = new UnrealBloomPass( new THREE.Vector2( this.window_width, window.innerHeight ), 1.5, 0.4, 0.85 );
				bloom_pass.threshold = 1;
				bloom_pass.strength = 1;
				bloom_pass.radius = 1;

				composer = new EffectComposer( renderer );
				composer.addPass( render_pass );
				composer.addPass( bloom_pass );
				composer.addPass( outputPass );
				
				//Controls
				controls = new OrbitControls( camera_default, renderer.domElement );
				controls.enableDamping = true;
				controls.dampingFactor = 0.25;
				controls.enablePan = false;
				controls.enableZoom = true;
				controls_top = new OrbitControls( camera_top, renderer.domElement );
				controls_top.enabled = false;
				controls.minPolarAngle = Math.PI/2 - 0.5;
				controls.maxPolarAngle = Math.PI/2 + 0.5;
				controls.minDistance = 80;
				controls.maxDistance = 400
				
				window.controls = controls;
				
				//Transform controls
				transform_control = new TransformControls( camera, renderer.domElement );
				transform_control.addEventListener( 'objectChange',  this.dragEndBlock );
				transform_control.addEventListener( 'dragging-changed',  this.updateCableMeta );
				transform_control.addEventListener( 'dragging-changed',  function ( event ) {
					dragging = event.value;
					controls.enabled = !event.value;
				} );
				transform_control.size = 0.8;
				window.transform_control = transform_control;				

				// Access the gizmo and its child objects
				var gizmo = transform_control.children[0];
				var hide = ['XY', 'YZ', 'XZ', 'X', 'Z', 'Y'];
				
				gizmo.traverse( child => {
					if(child.isMesh){
						child.material.color.set(0xFFFF00);
						child.material.opacity = 1;
						
						if(hide.indexOf(child.name) != -1){
							child.visible = false;
							child.layers.disable(0);
						}
					}
				});

				scene.add(transform_control);
				
				//Events
				window.addEventListener( 'resize', this.onWindowResize );
				document.addEventListener( 'mousemove', this.onMouseMove );
				window.addEventListener('wheel', this.onMousewheel)
				
				//Init
				loader = new FBXLoader();
				this.animate();
				this.addObjects();
			},
			
			startConfiguration( advanced, data = null ){
				this.show_presets = false;
				this.reset_data = data;
				
				this.advanced_mode = advanced;
				
				if(advanced){
					this.max_cable_length = 990;
					this.default_cable_length = 990;
					this.default_cable_offset = 820;
					camera_default.layers.disable(1);
					camera_default.layers.enable(2);
					camera_default.layers.enable(3);
				}else{
					this.max_cable_length = 370;
					this.default_cable_length = 370;
					this.default_cable_offset = 200;
					camera_default.layers.disable(3);
					camera_default.layers.disable(2);
					camera_default.layers.enable(1);
				}
				
				if(data != null){
					this.importConfiguration(data);
				}else{
					this.changeCablesNumber(4);
					this.changeRoomHeight(360);
					this.selectAll();
					this.changeLightsNumber(8);
					this.deselectAll();
				}
			},
			
			resetConfiguration(){
				if (window.confirm(this.locale.reset_confirm)) {
					this.startConfiguration( this.advanced_mode, this.reset_data );
				}
			},
			
			addObjects(){
				//Box
				box = new THREE.Mesh(
					new THREE.BoxGeometry( 700, 300, 700 ), 
					new THREE.MeshStandardMaterial( {
						color: 'white',
						side: THREE.BackSide
					} )
				);
				box.layers.set(1);
				scene.add( box );
				
				//Measure 
				measure_group = new THREE.Group();
				
				let geometry1 = new THREE.BufferGeometry().setFromPoints( [new THREE.Vector3(350,-150,0), new THREE.Vector3(350,150,0)] );
				let geometry2 = new THREE.BufferGeometry().setFromPoints( [new THREE.Vector3(150,0,0), new THREE.Vector3(0,0,0)] );
				let curve = new THREE.EllipseCurve( 0, 0, 10, 10, 0, 2 * Math.PI, false, 0 );
				let points = curve.getPoints( 64 )
				let geometry3 = new THREE.BufferGeometry().setFromPoints( points );
				let line_material = new THREE.LineBasicMaterial({color: 'black'});
				
				let measure_wall = new THREE.Line( geometry1, line_material );
				measure_wall.name = 'measure_wall';
								
				let circle_indicator1 = new THREE.Line( geometry3, line_material );
				let circle_indicator2 = new THREE.Line( geometry3, line_material );
				circle_indicator1.rotation.x = - Math.PI / 2;
				circle_indicator2.rotation.x = - Math.PI / 2;
				circle_indicator1.name = 'circle_indicator1';
				circle_indicator2.name = 'circle_indicator2';
				circle_indicator2.visible = false;
				
				measure_group.add( measure_wall );
				measure_group.add( circle_indicator1 );
				measure_group.add( circle_indicator2 );
				scene.add( measure_group );
				
				let text = document.createElement( 'div' );
				text.className = 'measure-divider';

				let label = new CSS2DObject( text );
				label.position.set( 350,0,0 );
				label.userData.is_label = true;
				label.name = 'measure_divider';
					
				scene.add(label);

				text = document.createElement( 'div' );
				text.className = 'measure-arrow-bottom';

				label = new CSS2DObject( text );
				label.position.set( 350,0,0 );
				label.userData.is_label = true;
				label.name = 'measure_arrow_bottom';
					
				scene.add(label);

				text = document.createElement( 'div' );
				text.className = 'measure-arrow-top';

				label = new CSS2DObject( text );
				label.position.set( 350,0,0 );
				label.userData.is_label = true;
				label.name = 'measure_arrow_top';
					
				scene.add(label);
				
				for(let i=1;i<=4; i++){
					let text = document.createElement( 'div' );
					text.className = (i != 3 && i != 4) ? 'measure-label-wall' : 'measure-label-floor';
					text.textContent = '150cm';

					let label = new CSS2DObject( text );
					label.position.set( 350,-75 + ((i-1)*150),0 );
					label.userData.is_label = true;
					label.name = 'label_m'+i;
					
					if(i == 4){
						label.visible = false;
					}
						
					scene.add(label);
				}
				
				bounding_box = new THREE.Box3();
				
				//Plane
				floor_plane = new THREE.Mesh( new THREE.PlaneGeometry( 100000, 100000 ), new THREE.MeshStandardMaterial( { color: 'white', side: THREE.FrontSide } ) );
				ceiling_plane = new THREE.Mesh( new THREE.PlaneGeometry( 100000, 100000 ), new THREE.MeshStandardMaterial( { color: 'white', side: THREE.BackSide } ) );
				floor_plane.position.y = -this.room_height/2;
				ceiling_plane.position.y = this.room_height/2;
				floor_plane.rotation.x = - Math.PI / 2;
				ceiling_plane.rotation.x = - Math.PI / 2;
				floor_plane.layers.set(2);
				ceiling_plane.layers.set(3);
				scene.add( floor_plane );
				scene.add( ceiling_plane );
				
				//Base
				base_group = new THREE.Object3D();
				base_group.position.setY(150 - this.base_data.height);
				scene.add(base_group);
				
				var bases = [4,8,12,18,24];
				bases.forEach( i => {
					loader.load( require('../assets/base-'+i+'.fbx'), function ( base ) {
						base.rotation.x = - Math.PI / 2;
						base.name = 'base_'+i;
						base.userData.is_base = true;
						base.userData.index = i;
						
						if(i != 4) base.visible = false;
						base_group.add(base);
					});
				});
				
				//Furniture
				loader.load( require('../assets/furniture.fbx'), ( f ) => {
					furniture = f;
					furniture.position.y -= this.room_height/2;
					
					let furniture_light = furniture.getObjectByName('Light');
					furniture.remove(furniture_light);
					
					furniture.getObjectByName('Divani').visible = false;
					furniture.getObjectByName('Tavolo_grande').visible = false;
					furniture.getObjectByName('Tavolo_piccolo').visible = false;
					
					furniture.getObjectByName(this.room_decoration).visible = true;
					
					scene.add(furniture);
					
					furniture.traverse( child => {
						if (child instanceof THREE.Mesh){
							let alpha = Math.random();
							let beta = Math.random();
							let gamma = Math.random();
							
							let colors = new Uint8Array( 3 );
							
							for ( let c = 0; c <= colors.length; c ++ ) {
								colors[ c ] = ( c / colors.length ) * 256;
							}
							
							let gradientMap = new THREE.DataTexture( colors, colors.length, 1,  ( renderer.capabilities.isWebGL2 ) ? THREE.RedFormat : THREE.LuminanceFormat );
							gradientMap.needsUpdate = true;
							let diffuseColor = new THREE.Color().setHSL( 0, 0, 100 ).multiplyScalar( 1 - beta * 0.2 );

							let material = new THREE.MeshToonMaterial( {
								color: diffuseColor,
								gradientMap: gradientMap
							} );
							
							child.material = material;
						}
					});	
				});

				//Silhouette
				loader.load( require('../assets/silhouette.fbx'), ( s ) => {
					silhouette = s;
					silhouette.position.y -= this.room_height/2;
					silhouette.position.x -= 155;
					silhouette.position.z -= 125;
					silhouette.rotateY(Math.PI+0.55 )
					silhouette.getObjectByName(Math.random()>0.5?'figura1':'figura2').visible = false;
					scene.add(silhouette);

					silhouette.traverse( child => {
						if (child instanceof THREE.Mesh){
							let alpha = Math.random();
							let beta = Math.random();
							let gamma = Math.random();
							
							let colors = new Uint8Array( 3 );
							
							for ( let c = 0; c <= colors.length; c ++ ) {
								colors[ c ] = ( c / colors.length ) * 256;
							}
							
							let gradientMap = new THREE.DataTexture( colors, colors.length, 1,  ( renderer.capabilities.isWebGL2 ) ? THREE.RedFormat : THREE.LuminanceFormat );
							gradientMap.needsUpdate = true;
							let diffuseColor = new THREE.Color().setHSL( 0, 0, 100 ).multiplyScalar( 1 - beta * 0.2 );

							let material = new THREE.MeshToonMaterial( {
								color: diffuseColor,
								gradientMap: gradientMap
							} );
							
							child.material = material;
						}
					});	
				});

			},
				
			changeFurniture(type){
				if(furniture){
					this.room_decoration = type;
					furniture.getObjectByName('Divani').visible = false;
					furniture.getObjectByName('Tavolo_grande').visible = false;
					furniture.getObjectByName('Tavolo_piccolo').visible = false;
					
					furniture.getObjectByName(type).visible = true;
				}
			},
				
			changeRoomHeight(e){
				let height = e.target ? e.target.value : e;
				let difference = height - this.room_height;
				this.room_height = height;
				
				camera_top.position.y = height/2 
				
				//Aggiorno la geometria della box
				box.geometry = new THREE.BoxGeometry( 700, height, 700 );
				floor_plane.position.y = -height/2 - 5
				ceiling_plane.position.y = height/2
				
				//Aggiorno posizione della base e dei punti di ancoraggio
				base_group.position.setY(this.anchor_point_base_height);
				anchors.position.setY(this.anchor_point_base_height);
				this.refreshAnchorPoints();
				
				//Aggiorno l'arredo 
				if(furniture){
					furniture.position.y = - height/2;
				}
				if(silhouette){
					silhouette.position.y = - height/2;
				}
				
				//Aggiorno la misura
				let measure_wall = measure_group.getObjectByName('measure_wall');
				measure_wall.geometry = new THREE.BufferGeometry().setFromPoints( [
					new THREE.Vector3(350,-(this.room_height/2),0), 
					new THREE.Vector3(350,this.room_height/2,0)
				]);
				let circle_indicator1 = measure_group.getObjectByName('circle_indicator1');
				let circle_indicator2 = measure_group.getObjectByName('circle_indicator2');
				circle_indicator1.position.y = -(this.room_height/2);
				circle_indicator2.position.y = -(this.room_height/2);
				
				//Aggiorno cavi e oggetti correlati
				this.anchor_points.forEach( (point, i) => {
					let cable = cables.getObjectByName('cable_'+i);
					let end_block = end_blocks.getObjectByName('end_block_'+i);
					end_block.position.y += difference/2;
					this.renderCable(cable, point, end_block.position);
				});
				
				//Aggiorno polar angle della camera
				var range = ((height-240)/520)+0.2;
				range = Math.min(0.75, range);
				controls.minPolarAngle = Math.PI/2 - range;
				controls.maxPolarAngle = Math.PI/2 + range;
				
				//Aggiorno altezza e zoom della camera
				var max_camera_height = 0;
				if(height > 500){
					max_camera_height = (height-500)/2
					controls.maxDistance = height-100
					
					if(this.camera_height > max_camera_height){
						this.changeCameraHeight(max_camera_height);
					}
					
					if(this.camera_height < -max_camera_height){
						this.changeCameraHeight(-max_camera_height);
					}
				}
				
				this.updateCablesTableData();
			},
			
			changeCameraHeight(e){
				let height = parseInt(e.target ? e.target.value : e);
				this.camera_height = height;
				controls.target.set(0, height, 0);
				controls.update();
			},
			
			changeCablesNumber(number){
				this.cables_number = number;
				transform_control.detach();
				
				this.base_data.radius = 17.5;
					
				if(number == 18) this.base_data.radius = 21.5;
				if(number == 24) this.base_data.radius = 27;
				
				let circle_indicator2 = measure_group.getObjectByName('circle_indicator2');
				let label_m4 = scene.getObjectByName('label_m4');
				circle_indicator2.visible = false;
				label_m4.visible = false;
			
				//Visibilità base
				base_group.traverse( base => {
					if(base.userData.is_base){
						base.visible = base.userData.index == number;
					}
				});
				
				//Render cavi
				this.selected_cables = [];
			
				if(anchors != null){
					this.disposeElement(anchors);
				}
				
				anchors = new THREE.Mesh( 
					new THREE.CircleGeometry( this.base_data.radius-3.5, number ), 
					new THREE.MeshBasicMaterial({color: 'red'})
				); 
				anchors.position.setY(this.anchor_point_base_height);
				anchors.rotateX(Math.PI / 2);
				
				this.refreshAnchorPoints();
				this.createCables();
				this.updateBoundingBox();
			},
			
			changeLightsColor(color){
				this.light_color = color;
				
				var new_color;
				
				switch(color){
					case 2700: new_color = new THREE.Color( 0xfffbdc ); break;
					case 3000: new_color = new THREE.Color( 0xffffff ); break;
					case 4000: new_color = new THREE.Color( 0xeeffff ); break;
				}
						
				end_blocks.traverse( child => {
					if(child.userData.is_light){
						child.color = new_color
					}
				});
						
				cables.traverse( child => {
					if(child.userData.is_sphere){
						child.material.emissive = new_color
					}
				});
			},
			
			refreshAnchorPoints(){
				let gp = anchors.geometry.attributes.position;
				let points = [];
				for(let i = 0;i < gp.count; i++){
					if(i != 0 && i != 1){
						let p = new THREE.Vector3().fromBufferAttribute(gp, i);
						p.name = 'anchor_point_'+i;
						anchors.localToWorld(p);
						points.push(p);
					}
				}
				
				this.anchor_points = points;
			},
			
			createCables(){
				if(anchors != null && this.cable_length != ''){
					if(cables != null){
						cables.removeFromParent();
						end_blocks.removeFromParent();
						this.disposeElement(cables);
						this.disposeElement(end_blocks);
					}
					
					cables = new THREE.Group();
					cables.userData.is_cables = true;
					end_blocks = new THREE.Group();
					end_blocks.userData.is_endblocks = true;
					this.radial_planes = [];
					
					//Materials
					let cable_material = new THREE.MeshPhongMaterial({color: 'black'});
					let end_block_material = new THREE.MeshPhongMaterial({color: 'black'});
					let line_material = new THREE.LineBasicMaterial({color: 0xa9a9a9,fog: false});//0xd9d9d9
					
					let sphere_material = new THREE.MeshStandardMaterial({ 
						color: 0xffffff,
						toneMapped: false,
						emissive: 0xffffff,
						emissiveIntensity: 1
					});
					
					
					this.anchor_points.forEach( (p, i) => {
						let group = new THREE.Group();
						group.name = 'group_'+i;
						cables.add(group);
						
						//Cavi
						let effective_cable_length = this.default_cable_length - this.default_cable_offset;
						let end_point = p.clone();
						end_point.y -= effective_cable_length;
						
						let curve = this.createCatenary( p, end_point, effective_cable_length );
						let cable = new THREE.Mesh( 
							new THREE.TubeGeometry( curve, 100, 0.2, 8, false ), 
							cable_material
						);
						cable.name = 'cable_'+i;
						cable.userData.index = i;
						cable.userData.type = 'detached';
						cable.userData.length = this.default_cable_length;
						cable.userData.offset = this.default_cable_offset;
						cable.userData.last_attached_position = null;
						cable.userData.lights_number = this.lights_number;
						cable.userData.lights_distance = this.lights_distance;
						
						group.add(cable);
						
						//Radial plane
						let radial_plane = new THREE.Plane();
						
						let point_angle = Math.atan2(p.z, p.x);
						let length = this.default_cable_length - this.default_cable_offset;
						let x = Math.cos(point_angle) * 50;
						let z = Math.sin(point_angle) * 50;
						let third_point = new THREE.Vector3(x,p.y - (length - 50),z);
						
						radial_plane.setFromCoplanarPoints(p, end_point, third_point);
						this.radial_planes.push(radial_plane);
						
						//Blocco
						let end_block = new THREE.Group();

						end_block.position.copy(end_point);
						end_block.name = 'end_block_'+i;
						end_block.userData.index = i;
						end_block.userData.is_end_block = true;
						end_block.layers.set(1);
						
						end_blocks.add(end_block);

						let end_block_obj = new THREE.Mesh( 
							new THREE.CylinderGeometry( 1.25, 1.25, 11, 16 ), 
							end_block_material
						); 
						end_block_obj.name = 'end_block_obj';
						end_block.add(end_block_obj);
						
						//label
						const text = document.createElement( 'div' );
						text.className = 'label';
						text.textContent = this.cables_letters[i];

						const label = new CSS2DObject( text );
						label.position.copy( end_block.position );
						label.name = 'label_'+i;
						label.userData.is_label = true;
						
						group.add(label);
						
						//Luci
						var points = curve.getSpacedPoints(effective_cable_length);
						var sphere_geometry = new THREE.SphereGeometry( sphere_radius, 12, 12 );
						var count = 0;
						
						for(var j=0; j< (this.advanced_mode ? 32 : 16); j++){
							var sphere = new THREE.Mesh( sphere_geometry, sphere_material ); 
							var index = (points.length - (1+this.lights_distance)) - (this.lights_distance*j);
							
							if(index > 0){
								sphere.position.set(points[index].x, points[index].y, points[index].z);
							}else{
								sphere.position.copy(p);
							}
							
							sphere.name = 'sphere_'+count;
							sphere.userData.is_sphere = true;
							group.add( sphere );
							
							if(count >= this.lights_number){
								sphere.visible = false;
							}
							
							count++;
						};
						
						//Illumination
						const pointLight = new THREE.PointLight( 0xffffff, 1.5, 500, 0 );
						pointLight.name = 'pointlight_'+i;
						pointLight.userData.is_light = true;
						end_block.add( pointLight );
						
						//Fili
						let line_curve = new THREE.LineCurve(new THREE.Vector3(), new THREE.Vector3(0,1000, 0));
						let line = new THREE.Mesh( 
							new THREE.TubeGeometry( line_curve, 100, 0.08, 8, false ), 
							line_material
						);
						line.userData.is_line = true;
						line.name = 'line_'+i;
						line.visible = false;
						end_blocks.add( line );

						let stopGroup = new THREE.Group();
						let cylinder = new THREE.Mesh(new THREE.CylinderGeometry(0.65, 0.65, 0.2, 16), cable_material);
						cylinder.position.y = -0.1;
						stopGroup.add(cylinder);
						cylinder =  new THREE.Mesh(new THREE.CylinderGeometry(0.45, 0.45, 2.5, 16), cable_material);
						cylinder.position.y = -1.35;
						stopGroup.add(cylinder);
						cylinder =  new THREE.Mesh(new THREE.CylinderGeometry(0.25, 0.25, 0.9, 16), cable_material);
						cylinder.position.y = -2.8;
						stopGroup.add(cylinder);

						let stop = this.mergeGeometries(stopGroup, cable_material);
						stop.name = 'stop';
						stop.userData.is_stop = true;
						stop.userData.index = i;
						stop.position.y = this.room_height/2 - end_block.position.y
						end_block.add(stop);

						/*loader.load( require('../assets/bloccafune.fbx'), function ( stop ) {
							stop.rotation.x = Math.PI;
							stop.name = 'stop';
							stop.userData.is_stop = true;
							stop.userData.index = i;
							stop.position.y = that.room_height/2 - end_block.position.y - 10
							end_block.add(stop);
						});*/
					});
					
					scene.add( cables );
					scene.add( end_blocks );
				}
				this.updateBoundingBox();
			},
			
			createCatenary(start_point, end_point, length){
				var distance = start_point.distanceTo(end_point);
			
				if( distance >= length || (start_point.x == end_point.x && start_point.z == end_point.z) ){
					return new THREE.CatmullRomCurve3( [start_point, end_point] );
				}else{
					//Per calcolare la curva ruoto i punti sul piano frontale
					let angle = Math.atan2(end_point.z - start_point.z, end_point.x - start_point.x);
					let pivot = new THREE.Vector3(0,1,0);
					let temp_point = new THREE.Vector3().copy(end_point);
					//Ruoto e traslo il punto di fine sul piano frontale
					temp_point.x -= start_point.x;
					temp_point.z -= start_point.z;
					temp_point.applyAxisAngle(pivot, angle);
				
					const p1 = {x: 0, y: start_point.y}
					const p2 =  {x: temp_point.x, y: temp_point.y}
					
					//Calcolo la curva sul piano frontale
					const result = getCatenaryCurve(p1, p2, length)
					
					if(result.curves){
						let middle_point = (p1.y+p2.y)/2
						let points = result.curves.map( c => {
							let point = new THREE.Vector3(p1.x + ( p2.x - c[0] ), middle_point +( middle_point- c[1] ), 0);
							//Ruoto e traslo i punti per ritornare sul piano originale
							point.applyAxisAngle(pivot, -angle);
							point.x += start_point.x;
							point.z += start_point.z;
							return point;
						});
						
						points.push(start_point);
						points = [end_point].concat(points);
						
						return new THREE.CatmullRomCurve3(points);
					}else{
						return new THREE.CatmullRomCurve3( [start_point, end_point] );
					}
				}
			},
			
			disposeElement(element){
				if (element.children.length > 0) {
					for (var x = element.children.length - 1; x >= 0; x--) {
						this.disposeElement(element.children[x])
					}
				}
				if (element.isMesh) {
					element.geometry.dispose();
					element.material.dispose();
				}
				if (element.parent) {
					element.parent.remove(element)
				}
			}, 
			
			selectCable(n){
				let circle_indicator2 = measure_group.getObjectByName('circle_indicator2');
				let label_m4 = scene.getObjectByName('label_m4');
				let index = this.selected_cables.indexOf(n);
				
				if(index == -1){
					this.selected_cables.push(n);
				}else{
					this.selected_cables.splice(index, 1);
				}
				
				//Gestione transform controls
				if(this.selected_cables.length == 0){
					transform_control.detach();
					circle_indicator2.visible = false;
					label_m4.visible = false;
					this.current_cable = null;
				}else{
					let cable = cables.getObjectByName('cable_'+this.selected_cables[0]);
					let end_block = end_blocks.getObjectByName('end_block_'+this.selected_cables[0]);
					this.current_cable = cable;
					this.current_offset = this.current_cable.userData.offset;
					this.current_cable_canopy_length = this.current_cable.userData.length - this.current_offset - this.current_cable.userData.lights_number*this.lights_distance;
					if(cable.userData.type == 'attached'){
						transform_control.attach(end_block);
						circle_indicator2.visible = true;
						label_m4.visible = true;
					}
				}
				
				//Gestione label
				cables.traverse( child => {
					if (child.userData.is_label){
						child.element.classList.remove('selected'); 
						child.element.classList.remove('main'); 
					}
				});
				
				this.selected_cables.forEach( i => {
					let label = cables.getObjectByName('label_'+i);	
					label.element.classList.add('selected'); 
				});
				
				if(this.selected_cables.length > 0){
					let label = cables.getObjectByName('label_'+this.selected_cables[0]);	
					label.element.classList.add('main'); 
				}
				
				this.updateCablesTableData();
			},
			
			selectAll(){
				this.anchor_points.forEach( (point, index) => {
					if(this.selected_cables.indexOf(index) == -1){
						this.selectCable(index);
					}
				});
			},
			
			deselectAll(){
				let circle_indicator2 = measure_group.getObjectByName('circle_indicator2');
				let label_m4 = scene.getObjectByName('label_m4');
				circle_indicator2.visible = false;
				label_m4.visible = false;
				
				transform_control.detach();
				this.selected_cables = [];
				this.cables_table_data = [];
				this.current_cable = null;
					
				cables.traverse( child => {
					if (child.userData.is_label){
						child.element.classList.remove('selected'); 
					}
				});
			},
			
			changeCableType(type, update = true ){
				let circle_indicator2 = measure_group.getObjectByName('circle_indicator2');
				let label_m4 = scene.getObjectByName('label_m4');
				
				//Aggiorno il tipo nel gruppo
				this.selected_cables.forEach( i => {
					let cable = cables.getObjectByName('cable_'+i);
					cable.userData.type = type;
				});
				
				if(type == 'detached'){
					transform_control.detach();
					circle_indicator2.visible = false;
					label_m4.visible = false;
					
					//Resetto i cavi in posizione verticale
					this.selected_cables.forEach( i => {
						let cable = cables.getObjectByName('cable_'+i);
						let line = scene.getObjectByName('line_'+i);
						let anchor_point = this.anchor_points[i];
						let end_point = new THREE.Vector3();
						let length = cable.userData.length - cable.userData.offset;
						end_point.copy(anchor_point);
						end_point.y -= length;
						line.visible = false;
						
						if(update){
							this.renderCable(cable, anchor_point, end_point);
						}
					});
					
				}else{
					//Imposto i cavi in una curva default
					this.selected_cables.forEach( i => {
						let cable = cables.getObjectByName('cable_'+i);
						let line = scene.getObjectByName('line_'+i);
						line.visible = true;
						let p = this.anchor_points[i];
						if(cable.userData.last_attached_position == null){
							let point_angle = Math.atan2(p.z, p.x);
							let length = cable.userData.length - cable.userData.offset;
							
							let x = Math.cos(point_angle) * 50;
							let z = Math.sin(point_angle) * 50;
							
							let end_position = new THREE.Vector3(x,p.y - (length - 50),z);
							
							if(update){
								this.renderCable(cable, p, end_position);
							}
						}else{
							if(update){
								this.renderCable(cable, p, cable.userData.last_attached_position);
							}
						}
					});
					
					//Mostro i controlli
					let end_block = end_blocks.getObjectByName('end_block_'+this.current_cable.userData.index);
					transform_control.attach(end_block);
					circle_indicator2.visible = true;
					label_m4.visible = true;
				}
				
				this.updateCablesTableData();
			},
			
			changeLightsNumber(number){
				if(!this.advanced_mode){
					switch(number){
						case 8:
							this.max_cable_length = 200;
						break;
						case 12:
							this.max_cable_length = 270;
						break;
						case 16:
							this.max_cable_length = 370;
						break;
					}
				}else{
					this.max_cable_length = 990;
				}
			
				this.selected_cables.forEach( i => {
					let group = scene.getObjectByName('group_'+i); 
					let cable = cables.getObjectByName('cable_'+i);
					let end_block = end_blocks.getObjectByName('end_block_'+i);
					let anchor_point = this.anchor_points[i];
					cable.userData.lights_number = number;
					this.max_cable_canopy_length = this.max_cable_length - number*this.lights_distance;
					this.current_cable_canopy_length = Math.min(this.current_cable_canopy_length,this.max_cable_canopy_length);
					group.traverse( child => {
						if (child.userData.is_sphere){
							child.visible = false;
						}
					});
						
					for(var j=0; j<number; j++){
						let sphere = group.getObjectByName('sphere_'+j); 
						sphere.visible = true;
					}
					
					this.renderCable(cable, anchor_point, end_block.position);
				});
				
				this.updateCablesTableData();
				this.changeCableCanopyLength(this.current_cable_canopy_length);
			},
			
			changeLightsDistance(distance){
				this.lights_distance = distance;
			
				this.selected_cables.forEach( i => {
					let cable = cables.getObjectByName('cable_'+i);
					let end_block = end_blocks.getObjectByName('end_block_'+i);
					let anchor_point = this.anchor_points[i];
					
					cable.userData.lights_distance = distance;
					
					let min_cable_canopy_length = 10 + cable.userData.lights_number*this.lights_distance;
					
					this.max_cable_canopy_length = this.max_cable_length - cable.userData.lights_number*this.lights_distance;
					this.current_cable_canopy_length = cable.userData.length - cable.userData.offset - cable.userData.lights_number*this.lights_distance;
					
					if(this.current_cable_canopy_length < 10){
						this.changeCableCanopyLength(10);
					}
					
					this.renderCable(cable, anchor_point, end_block.position);
				});
				
				this.updateCablesTableData();
			},
			
			
			changeCableCanopyLength(e){
				let value = e.target ? e.target.value : e;
				
				let canopy_cable_length = parseInt(value);
				let first_cable = cables.getObjectByName('cable_'+this.selected_cables[0]);
				let first_anchor_point = this.anchor_points[this.selected_cables[0]];
				let first_end_block = end_blocks.getObjectByName('end_block_'+this.selected_cables[0]);
				
				canopy_cable_length = Math.min(canopy_cable_length, this.max_cable_canopy_length);
				//canopy_cable_length = Math.min(canopy_cable_length, this.room_height - first_cable.userData.lights_number*this.lights_distance)
				
				let distance = first_anchor_point.distanceTo(first_end_block.position);
				let length = canopy_cable_length + first_cable.userData.lights_number*this.lights_distance
				
				//Se il cavo è attacco non permetto al cavo di rientrare oltre alla lunghezza del cavo
				if(first_cable.userData.type == 'attached' && distance > length) {
					canopy_cable_length = Math.floor(distance - (first_cable.userData.lights_number*this.lights_distance));
				}
				
				let offset = first_cable.userData.length - canopy_cable_length - first_cable.userData.lights_number*this.lights_distance;
				this.current_offset = offset;
				this.current_cable_canopy_length = canopy_cable_length;

				this.selected_cables.forEach( i => {
					let cable = cables.getObjectByName('cable_'+i);
					let end_block = end_blocks.getObjectByName('end_block_'+i);
					let anchor_point = this.anchor_points[i];
					
					//Se il cavo non è attaccato trascino anche il blocco finale
					if(cable.userData.type == 'detached'){
						let difference = cable.userData.offset - offset
						end_block.position.y -= difference;
					}
					
					cable.userData.offset = offset;

					this.renderCable(cable, anchor_point, end_block.position);
				});
				
				this.updateCablesTableData();
			},
			
			changeCableLength(e){
				let length = parseInt(e.target.value);
				let first_cable = cables.getObjectByName('cable_'+this.selected_cables[0]);
				
				if(length - first_cable.userData.offset > this.room_height){
					length = first_cable.userData.offset -this.room_height
					this.current_length = length;
				}
				
				this.selected_cables.forEach( i => {
					let cable = cables.getObjectByName('cable_'+i);
					let end_block = end_blocks.getObjectByName('end_block_'+i);
					let anchor_point = this.anchor_points[i];
					
					let difference = cable.userData.length - length
					end_block.position.y -= difference;
					cable.userData.length = length;

					this.renderCable(cable, anchor_point, end_block.position);
				});
				
				this.updateCablesTableData();
			},
				
			dragEndBlock(e){
				var end_block = transform_control.object;
				if(typeof end_block == 'undefined' || !end_block.userData.is_end_block) return;
				
				
				//Calcoli
				var index = parseInt(end_block.name.replace('end_block_', ''));
				var anchor_point = this.anchor_points[index];
				let center_point = new THREE.Vector3(0,end_block.position.y,0);
				let angle = Math.atan2(end_block.position.z, end_block.position.x);
				let original_angle = Math.atan2(anchor_point.z, anchor_point.x);
				let angle_correction =  angle - original_angle;
				
				//Snapback troppo lontano
				var cable = cables.getObjectByName('cable_'+index);
				
				this.changeCableType(cable.userData.type, false)
				
				var length = anchor_point.distanceTo(end_block.position);
				var max_length = cable.userData.length - cable.userData.offset;
			
				if(length > max_length){
					let direction = new THREE.Vector3(); 
					let new_end_position = new THREE.Vector3();
					
					direction.subVectors( end_block.position, anchor_point).normalize();
					new_end_position.addVectors ( anchor_point, direction.multiplyScalar( max_length-1 ) );
					end_block.position.copy(new_end_position);
				}
				
				//Snapback verticale
				if(end_block.position.y > this.room_height/2){
					end_block.position.y = this.room_height/2;
				}
				
				//Movimento sul piano radiale
				if(!this.unbounded_mode){
					let radial_plane = this.radial_planes[index];
					let projection = new THREE.Vector3();
					
					radial_plane.projectPoint(end_block.position, projection);
					end_block.position.copy(projection);
					
					//Snapback troppo vicino
					var vertical_point = new THREE.Vector3(anchor_point.x+0.1,end_block.position.y, anchor_point.z+0.1);
					var vertical_center = new THREE.Vector3(0, end_block.position.y, 0); 
					
					if(Math.round(angle_correction) != 0 || ( vertical_center.distanceTo(end_block.position) < vertical_center.distanceTo(vertical_point) )){
						end_block.position.copy(vertical_point);
					}
				}
				
				this.renderCable(cable, anchor_point, end_block.position);
				
				//Movimento gruppo
				let distance = center_point.distanceTo(end_block.position);
				angle = Math.atan2(end_block.position.z, end_block.position.x);
				original_angle = Math.atan2(anchor_point.z, anchor_point.x);
				angle_correction =  angle - original_angle;
				
				this.anchor_points.forEach( (p, i) => {
					if(i != index && this.selected_cables.indexOf(i) != -1){
						let point_angle = Math.atan2(p.z, p.x);
						point_angle+=angle_correction
						
						let x = Math.cos(point_angle) * distance;
						let z = Math.sin(point_angle) * distance;
						
						let point_cable = cables.getObjectByName('cable_'+i);
						let end_position = new THREE.Vector3(x,end_block.position.y,z);
						
						this.renderCable(point_cable, p, end_position);
					}
				});
				
				this.updateCablesTableData();
			},
			
			renderCable(cable, start_point, end_point){
				//Ricreo la geometria del cavo
				let length = cable.userData.length - cable.userData.offset;
				let curve = false;
				switch(cable.userData.type){
					case 'attached':
						curve = this.createCatenary( start_point, end_point, length );
						cable.geometry = new THREE.TubeGeometry( curve, 100, 0.2, 8, false );
					break;
					case 'detached':
						curve = this.createCatenary( start_point, end_point, length );
						cable.geometry = new THREE.TubeGeometry( curve, 100, 0.2, 8, false );
						cable.scale.set(1,1,1);
					break;
				}
				
				
				//Aggiorno la posizione delle luci
				var group = scene.getObjectByName('group_'+cable.userData.index); 
				var lights_number = cable.userData.lights_number;
				var points = curve.getSpacedPoints(length);
				var count = 0;
				
				if(cable.userData.type=='attached'){
					for(var i = 0, j = points.length; i<j;i++){
						points[i];
					}
				}
				if(points[0].distanceTo(end_point) < points[0].distanceTo(start_point)){
					points.reverse();
				}
				var last_position = new THREE.Vector3;
				
				for(var j=0; j<(this.advanced_mode ? 32 : 16); j++){
					var sphere = group.getObjectByName('sphere_'+count); 
					var index = (points.length - (1+this.lights_distance)) - (this.lights_distance*j);
					
					if(index > 0){
						sphere.position.set(points[index].x, points[index].y, points[index].z);
						last_position.copy(sphere.position);
					}else{
						sphere.position.copy(last_position);
					}
					count++;
				};
				
				//Aggiorno il blocco
				let point_endblock = end_blocks.getObjectByName('end_block_'+cable.userData.index);
				point_endblock.position.copy(end_point);
				let label = scene.getObjectByName('label_'+cable.userData.index);
				label.position.copy(end_point);
				
				if(cable.userData.type=='attached'){
					point_endblock.getObjectByName('end_block_obj').position.set(0,5.5,0);
					label.translateY(5.5);
				} else {
					point_endblock.getObjectByName('end_block_obj').position.set(0,-5.5,0);
					label.translateY(-5.5);
				}
				if(point_endblock.getObjectByName('stop')) point_endblock.getObjectByName('stop').position.y = this.room_height/2 - point_endblock.position.y;
				
				//Aggiorno il filo
				let line = scene.getObjectByName('line_'+cable.userData.index);
				line.position.copy(end_point);
				
				this.updateBoundingBox();
			},
			
			updateCableMeta(){
				var end_block = transform_control.object;
				if(typeof end_block == 'undefined' || !end_block.userData.is_end_block) return;
				
				var index = parseInt(end_block.name.replace('end_block_', ''));
				let cable = scene.getObjectByName('cable_'+index);
				
				let position = new THREE.Vector3();
				cable.userData.last_attached_position = position.copy(end_block.position)
			},
			
			updateCablesTableData(){
				let center = new THREE.Vector3();
				let plane_position = new THREE.Vector3();
				this.cables_table_data = {};
				
				this.selected_cables.forEach( i => {
					let cable = cables.getObjectByName('cable_'+i);
					let end_block = end_blocks.getObjectByName('end_block_'+i);
					
					bounding_box.setFromObject(cable);
					plane_position.copy(end_block.position).setY(0);
					
					var cable_data = {
						canopy_to_sphere : cable.userData.length - cable.userData.offset - cable.userData.lights_number*this.lights_distance,
						radius : Math.round(center.distanceTo(plane_position)),
						height : this.room_height - Math.round(bounding_box.max.y - bounding_box.min.y)
					};
					this.cables_table_data[i] = cable_data;
				});
				
				this.selected_cables = this.selected_cables.slice();
			},
			
			updateBoundingBox(){
				bounding_box.setFromObject(cables);

				this.bounds.height = bounding_box.max.y - bounding_box.min.y;
				this.bounds.width = bounding_box.max.x - bounding_box.min.x;
				this.bounds.depth = bounding_box.max.z - bounding_box.min.z;
				
				this.bounds.height = Math.min(this.bounds.height, this.room_height);
				
				var label_m1 = scene.getObjectByName('label_m1');
				var label_m2 = scene.getObjectByName('label_m2');
				var label_m3 = scene.getObjectByName('label_m3');
				var label_m4 = scene.getObjectByName('label_m4');
				var measure_divider = scene.getObjectByName('measure_divider');
				let circle_indicator1 = measure_group.getObjectByName('circle_indicator1');
				let circle_indicator2 = measure_group.getObjectByName('circle_indicator2');
				
				measure_divider.position.y = (this.room_height/2)-this.bounds.height;
				scene.getObjectByName('measure_arrow_top').position.y = -this.room_height/2;
				scene.getObjectByName('measure_arrow_bottom').position.y = this.room_height/2;
				
				label_m1.element.textContent = Math.round(this.bounds.height)+'cm';
				label_m2.element.textContent = Math.round(this.room_height-this.bounds.height)+'cm';
				label_m1.position.y = (this.room_height/2) - (this.bounds.height/2)
				label_m2.position.y = -(this.room_height/2) + ((this.room_height-this.bounds.height)/2);
				
				//Floor measure
				let center = new THREE.Vector3();
				let radius = 0;
				let selected_radius = 0;
				
				end_blocks.traverse( child => {
					if(child.userData.is_end_block){
						let plane_position = new THREE.Vector3().copy(child.position).setY(0);
						let current_radius = center.distanceTo(plane_position);
						
						if(this.current_cable && child.userData.index == this.current_cable.userData.index){
							selected_radius = current_radius;
						}
						
						radius = Math.max(radius, current_radius);
						
						let line_height = (this.room_height/2) - child.position.y;
						let line = scene.getObjectByName('line_'+child.userData.index);
						line.scale.y = line_height/1000;
					}
				});
				
				label_m3.element.textContent = '⌀ '+Math.round(radius*2)+'cm';
				label_m3.position.x = radius+25;
				label_m3.position.y = -(this.room_height/2);
				
				label_m4.element.textContent = '⌀ '+Math.round(selected_radius*2)+'cm';
				label_m4.position.x = -selected_radius-25;
				label_m4.position.y = -(this.room_height/2);
				
				let curve1 = new THREE.EllipseCurve( 0, 0, radius, radius, 0, 2 * Math.PI, false, 0 );
				let points1 = curve1.getPoints( 64 )
				circle_indicator1.geometry = new THREE.BufferGeometry().setFromPoints( points1 );
				
				let curve2 = new THREE.EllipseCurve( 0, 0, selected_radius, selected_radius, 0, 2 * Math.PI, false, 0 );
				let points2 = curve2.getPoints( 64 )
				circle_indicator2.geometry = new THREE.BufferGeometry().setFromPoints( points2 );
			},
			
			toggleCamera(){
				if(this.current_camera == 'default'){
					camera = camera_top;
					this.current_camera = 'top';
					camera.position.copy(topCameraStartingPosition);
					base_group.position.y -= 300;
					furniture.visible = false;
					silhouette.visible = false;
				}else{
					camera = camera_default;
					this.current_camera = 'default';
					camera.position.copy(defaultCameraStartingPosition);
					base_group.position.y += 300;
					furniture.visible = true;
					silhouette.visible = true;
				}
				transform_control.camera = camera;
				this.changeRoomHeight(this.room_height);
			},
			
			animate(){
				if(!this.stop_animation){
					requestAnimationFrame( this.animate );
					renderer.render( scene, camera );
					label_renderer.render( scene, camera );
					controls.update();
				}
			},
			
			onWindowResize(){
				this.window_width = this.getWindowWidth();
				let aspect = this.window_width / window.innerHeight;
				
				camera_default.aspect = aspect;
				camera_default.updateProjectionMatrix();
				
				camera_top.left = - frustum_size * aspect / 2;
				camera_top.right = frustum_size * aspect / 2;
				camera_top.top = frustum_size / 2;
				camera_top.bottom = - frustum_size / 2;
				camera_top.updateProjectionMatrix();
				
				renderer.setPixelRatio( window.devicePixelRatio );
				renderer.setSize( this.window_width, window.innerHeight );
				composer.setSize( this.window_width, window.innerHeight );
				label_renderer.setSize( this.window_width, window.innerHeight );
			},
			
			getWindowWidth(){
				return window.innerWidth - ( this.$refs.info ? this.$refs.info.offsetWidth : 0 );
			},
			
			onMouseMove(e) {
				mouse.x = ( (e.clientX-( this.$refs.info ? this.$refs.info.offsetWidth : 0 )) / this.window_width ) * 2 - 1;
				mouse.y = - ( e.clientY / window.innerHeight ) * 2 + 1;
			},
			
			onMousewheel(e) {
				if(this.current_camera == 'top'){
				
					let aspect = this.window_width / window.innerHeight;
					this.wheel_delta += e.deltaY
					this.wheel_delta = Math.max(100, this.wheel_delta);
					this.wheel_delta = Math.min(500, this.wheel_delta);
					
					camera_top.left = - (frustum_size * aspect * (this.wheel_delta/100)) / 2;
					camera_top.right = (frustum_size * aspect * (this.wheel_delta/100)) / 2;
					camera_top.top = (frustum_size * (this.wheel_delta/100)) / 2;
					camera_top.bottom = - (frustum_size * (this.wheel_delta/100)) / 2;
				
					camera_top.updateProjectionMatrix();
				}
			},
			
			prepareRecap(){
				if(this.current_camera == 'top'){
					this.toggleCamera();
				}
			
				this.setRecap(true);
				this.exportConfiguration();
				
				setTimeout( () => {
					var	canvas = document.getElementById('canvas');
					
					this.selected_cables = [];
					measure_group.visible = false;
					transform_control.detach();
				
					//Foto 1
					this.current_camera = 'default';
					camera = camera_default;
					this.updateCanvasSize(1200, 1200);
					
					setTimeout( () => {
						var image = canvas.toDataURL("image/jpeg", 0.95);
						this.setImage1(image);
						
						furniture.visible = false;
						this.animate();
						
						//Foto 2
						camera = camera_top;
						setTimeout( () => {
							//var image = canvas.toDataURL();
							//this.setImage2(image);
							
							//Reset
							camera = camera_default;
							camera.position.copy(defaultCameraStartingPosition);
							camera.zoom = 1;
							this.changeCameraHeight(this.camera_height);
							this.updateCanvasSize(window.innerWidth, window.innerHeight);
							this.onWindowResize();
							measure_group.visible = true;
							furniture.visible = true;
							
							setTimeout( this.exportModel, 50 );
						}, 1);
						
					}, 1)
					
				}, 500)
			},
			
			updateCanvasSize(width, height){
				var	canvas = document.getElementById('canvas');
				
				canvas.setAttribute('width', width/window.devicePixelRatio);
				canvas.setAttribute('height', height/window.devicePixelRatio);
				
				renderer.setSize( width/window.devicePixelRatio, height/window.devicePixelRatio );
				
				camera_default.aspect = width / height;
				camera_top.aspect = width / height;
				if(this.advanced_mode){
					camera_default.position.copy(advancedScreenshotCameraStartingPosition);
				}else{
					camera_default.position.copy(screenshotCameraStartingPosition);
				}
				camera_top.position.copy(topCameraStartingPosition);
				camera_default.updateProjectionMatrix();
				camera_top.updateProjectionMatrix();
				
				this.animate();
			},
			
			exportConfiguration(){
				var data = {
					advanced_mode : this.advanced_mode,
					room_height : this.room_height,
					room_decoration : this.room_decoration,
					light_color : this.light_color,
					bounds : this.bounds,
					cables : []
				};
				
				this.anchor_points.forEach( (point, i) => {
					let cable = cables.getObjectByName('cable_'+i);
					let end_block = end_blocks.getObjectByName('end_block_'+i);
					
					let cable_data = {
						index : i,
						type : cable.userData.type,
						length : cable.userData.length,
						offset : cable.userData.offset,
						lights_number : cable.userData.lights_number,
						lights_distance : cable.userData.lights_distance,
						position : end_block.position
					}
					data.cables.push(cable_data);
				});
				
				this.setConfigurationData(JSON.stringify(data));
			},
			
			importConfiguration(data){
				data = JSON.parse(data);
				
				this.changeCablesNumber(data.cables.length);
				this.changeRoomHeight(data.room_height);
				this.changeFurniture(data.room_decoration);
				this.changeLightsColor(data.light_color)
				
				this.room_height_model = data.room_height;
				
				data.cables.forEach( cable_data => {
					let cable = cables.getObjectByName('cable_'+cable_data.index);
					let end_block = end_blocks.getObjectByName('end_block_'+cable_data.index);
					let anchor_point = this.anchor_points[cable_data.index];
					
					this.selected_cables = [cable_data.index];
					this.current_cable = cable;
					
					this.changeLightsNumber(cable_data.lights_number)
					this.changeCableType(cable_data.type)
					
					cable.userData.type = cable_data.type;
					cable.userData.length = cable_data.length;
					cable.userData.offset = cable_data.offset;
					cable.userData.lights_number = cable_data.lights_number;
					
					let label_m4 = scene.getObjectByName('label_m4');
					label_m4.visible = false;
					end_block.position.copy(cable_data.position);
					this.renderCable(cable, anchor_point, end_block.position);
				});
				
				window.cables = cables;
					
				this.selected_cables = [];
				this.current_cable = null;
				transform_control.detach();
			},

			mergeGeometries(object, material = false){
				let geometries = [];
				if(object instanceof Array){
					object.forEach(child=>{
						if (child instanceof THREE.Mesh) {
							child.updateMatrixWorld();
							let geometry = child.geometry.clone();
						    geometry.applyMatrix4(child.matrixWorld); // Apply world matrix
						    geometries.push(geometry);
						}
					})
				} else {
					object.traverse(function(child) {
					    if (child instanceof THREE.Mesh) {
					    	child.updateMatrixWorld();
					        let geometry = child.geometry.clone();
					        geometry.applyMatrix4(child.matrixWorld); // Apply world matrix
					        geometries.push(geometry);
					    }
					});
				}
				if(!material) material =  new THREE.MeshNormalMaterial();
				let mergedGeometry = BufferGeometryUtils.mergeGeometries(geometries);
				let mergedMesh = new THREE.Mesh(mergedGeometry, material);
				return mergedMesh;
			},
			
			exportModel(){
				console.log(2);
				const exporter = new MultidotExporter();
				/*
				scene.remove(furniture);
				scene.remove(measure_group);
				scene.remove(box);
				scene.remove(plane);
				scene.remove(silhouette);
				scene.remove(transform_control);
				*/
				//var to_remove = [];

				let exp_canopy = [],
					exp_spheres = [],
					exp_cables = [],
					exp_cableLines = [];

				scene.traverse( child => {
					if(!child.userData.is_label && child.type != 'AmbientLight' && child.visible ){
						if(child.name!='' && (child.isMesh===true)){
							let type = child.name.split('_')[0];
							switch(type){
								case 'sphere':
									exp_spheres.push(child);
								break;
								case 'cable':
								case 'end':
								case 'stop':
									exp_cables.push(child);
								break;
								case 'line':
									exp_cableLines.push(child);
								break;
							}
						}
					}
				});
				
				base_group.traverse( child => {
					if(child.userData.is_base && child.visible){
						child.traverse( subchild => {
							let type = subchild.name.split('_')[0];
							if(!subchild.userData.is_label && subchild.type != 'AmbientLight' && type == 'rosone'){
								exp_canopy.push(subchild);
							}
						});
					}
				});
				

				/*to_remove.forEach(element => {
					element.child.removeFromParent();
				});
				*/
				let export_scene = new THREE.Scene();
				//export_scene.scale.set(0.01,0.01,0.01);
				
				if(exp_cables.length > 0){
					var mergedCables = this.mergeGeometries(exp_cables);
					mergedCables.name = 'powerCables';
					mergedCables.translateY(-this.room_height/2);
					export_scene.add(mergedCables);
				}

				if(exp_cableLines.length > 0){
					var mergedCableLines = this.mergeGeometries(exp_cableLines);
					mergedCableLines.name = 'ironCables';
					mergedCableLines.translateY(-this.room_height/2);
					export_scene.add(mergedCableLines);
				}

				if(exp_spheres.length > 0){
					var mergedSpheres = this.mergeGeometries(exp_spheres);
					mergedSpheres.name = 'spheres';
					mergedSpheres.translateY(-this.room_height/2);
					export_scene.add(mergedSpheres);
				}

				if(exp_canopy.length > 0){
					var mergedCanopy = this.mergeGeometries(exp_canopy);
					mergedCanopy.name = 'canopy';
					mergedCanopy.translateY(-this.room_height/2);
					export_scene.add(mergedCanopy);
				}
				
				
				renderer.render( export_scene, camera );
				this.animate();
				renderer.render( export_scene, camera );

				const data = exporter.parse( export_scene );
				var blob = new Blob( [ data ], { type: 'text/plain' } );
				
				const zip = new JSZip();
				zip.file('multidot.obj', blob)
				
				zip.generateAsync({type:'blob', compression: 'DEFLATE'})
					.then(content =>{
						//saveAs(content, "multidot.zip");
						this.setModelFile(content);
						
						/*to_remove.forEach(element => {
							element.parent.add(element.child);
						});
						
						scene.add(furniture);
						scene.add(measure_group);
						scene.add(box);
						scene.add(plane);
						scene.add(silhouette);
						scene.add(transform_control);*/
					});
			}
		}
	}
	
	
	function radiansToDegrees(radians){
		return radians * (180/Math.PI);
	}
	
	
</script>

<style lang="scss">
	#configurator{
		position: relative;
		overflow: hidden;
		height: 100vh;
		
		.wrapper{
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			
			.info{
				height: 100%;
				background: white;
				width: 475px;
				min-width: 475px;
				overflow-y: auto;
				
				.go-back{
					white-space: nowrap;
					cursor: pointer;
					
					svg{
						margin-right: 10px;
					}
				}
				
				.panel,
				.toggle-panel{
					border-top: 1px solid black;
					padding: 10px 0;
					
					.panel-header{
						cursor: pointer;
						
						.toggle-icon{
							pointer-events: none;
						}
					}
					.panel-content{
						position: relative;
						padding: 20px 0;
						
						.field{
							margin-bottom: 16px;
							
							label{
								display: block;
								margin-bottom: 8px;
							}
							.range-container{
								.input-button{
									width: 25%;
									min-width: 120px;
								}
							}
						}
						.select-all,
						.deselect-all{
							cursor: pointer;
							width: 38px;
							height: 38px;
							
							svg{
								border-radius: 50%;
								transition: background 0.2s;
								
								&:hover{
									background: #E0E0E0;
								}
							}
						}
						
						.base-schema{
							position: relative;
							border-radius: 50%;
							background: #D9D9D9;
							margin: 20px auto;
							margin-top: 60px;
							
							--d: 40px; /* cable size */
							--r: 130px; /* radius */
							--s: 280px; /* container size */
							width: var(--s); height: var(--s);
							  
							.cable{
								position: absolute;
								border-radius: 50%;
								border: 1px solid black;
								background: white;
								text-align: center;
								transition: background 0.2s;
								cursor: pointer;

								top: 50%; left: 50%;
								margin: calc(-.5*var(--d));
								width: var(--d); height: var(--d);
								--az: calc(var(--i)*1turn/var(--m));
								transform: 
									rotate(var(--az)) 
									translate(var(--r))
									rotate(calc(-1*var(--az)));
									
								span{
									position: absolute;
									line-height: 38px;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;
									opacity: 1;
									transition: opacity 0.2s;
									
									&.add{
										opacity: 0;
									}
								}
									
								&:not(.selected):hover{
									background: #E0E0E0;
								}
									
								&.selected{
									background: black;
									color: white;
								}
									
								&.main{
									background: #FFFF00;
									color: black;
								}
								
							}
							
							&.select-mode .cable{
								&:not(.selected):not(:hover){
									span{
										&.label{
											opacity: 0;
										}
										&.add{
											opacity: 1;
										}
									}
								}
							}
						}
						
						.cables-table{
							padding: 12px;
							background: #EDEDED;
							border-radius: 16px;
							
							table{
								width: 100%;
								border-spacing: 0;
								
								tr{
									th{
										font-weight: normal;
										border-bottom: solid 1px #B3B3B3;
										padding-bottom: 8px;
									}
									td{
										padding: 4px 0;
										text-align: center;
										border-bottom: solid 1px #B3B3B3;
									}
								}
							}
						}
						
						&.incongruent-type .cable-type .switch .option.current,
						&.incongruent-spheres .spheres-number .switch .option.current{
							background: #D4D4D4;
							color: black;
							cursor: pointer;
						}
						
						&.incongruent-length{
							input[type="range"]::-webkit-slider-thumb {
								background-color: #D4D4D4;
							}

							input[type="range"]::-moz-range-thumb {
								background-color: #D4D4D4;
							}
						}
					}
				}
			}
			
			.view{
				position: relative;
				width: 100%;
						
				#canvas-container{
					canvas{
						position: absolute;
					}
				}
				
				.camera-control{
					position: absolute;
					right: 30px;
					top: 50%;
					height: 32px;
					width: 300px;
					padding: 6px 10px;
					border-radius: 15px;
					background: #E0E0E0;  
					transform: translate(50%, -50%) rotate(-90deg);
					
					.range-wrapper{
						margin-right: 0;
					}
				}
				
				.actions{
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					padding: 20px;
					
					svg{
						margin-right: 10px;
					}
				}
				
				.reset-button{
					position: absolute;
					top: 20px;
					left: 20px
				}
			}
		}
		
		.mobile-overlay{
			display: none;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: #FDF6C0;
			z-index: 33;
			flex-direction: column;
			
			a{
				text-decoration: none;
			}
		}
		
		
		@media screen and (max-width: 799px) {
			.mobile-overlay{
				display: flex;
			}
		}
	}
</style>
